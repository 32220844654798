<template>
    <router-view></router-view>
</template>

<script>
  export default {
    
  }
</script>

<style>
body{
  background-color: #f9f9f9;
}
</style>

