import { createRouter, createWebHashHistory } from "vue-router"

const routes = [
    {
        path: '/',
        name: 'userLogin',
        component: () => import('@/views/userLogin/userLogin')
    }, {
        path: '/Home',
        name: 'homePage',
        component: () => import('@/views/homePage/homePage'),
        redirect: 'userList',
        children: [
            {
                path: '/userList',
                name: 'userList',
                component: () => import('@/views/userManage/userList'),
            },
            {
                path: '/demandList',
                name: 'demandList',
                component: () => import('@/views/demandManage/demandList'),
            },
            {
                path: '/answersList',
                name: 'answersList',
                component: () => import('@/views/answers/answersList'),
            },
            {
                path: '/ordersGood',
                name: 'ordersGood',
                component: () => import('@/views/orders/ordersGood'),
            },
            {
                path: '/recordData',
                name: 'recordData',
                component: () => import('@/views/record/recordData'),
            }

        ]
    }
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

export default router
