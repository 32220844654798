import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import * as ElIcon from '@element-plus/icons-vue'
import Axios from './request/axios';
 
const app = createApp(App)

router.beforeEach((to,from,next)=>{
  // to: 从哪个页面
  // from: 到哪个页面
  // next: 只有执行next()页面
 
  //判断用户是否登录
  if(!window.sessionStorage.getItem('token')){
    // 未登录，跳转到login页面
    if( to.path === '/'){
      // ElementPlus.ElMessage.error('请先登录！')
      next()
      return
    }
    ElementPlus.ElMessage.error('请先登录！')
    next('/')
  }else{
    // 已登录
    next()
  }
})


Object.keys(ElIcon).forEach((key) => {
    app.component(key, ElIcon[key])
  })
//挂载
app.use(ElementPlus)
app.use(router)
app.use(Axios)
app.mount('#app')
